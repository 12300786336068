<template>
  <b-card-code title="微信列表">
    <!-- search input -->

    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-login
      variant="outline-primary"
    >
      创建渠道活码
    </b-button>

    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="添加"
      cancel-title="关闭"
      centered
      title="生成物料数量"
      @ok="generate"
    >
      <b-form>
        <b-form-group>
          <label for="module">渠道名称:</label>

          <v-select
            v-model="ChannelSelect"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :options="ChannelOptions"
          />
        </b-form-group>

        <b-form-group label="部门名称" label-for="dname">
          <b-form-select
            v-model="roleSelect"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="roleOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="user-dname"
            @input="changeAcc"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="搜索"
            type="text"
            class="d-inline-block"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
      @on-selected-rows-change="selectionChanged"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'url'" class="text-nowrap">
          <b-button
            :id="props.row.id"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
          >
            点击查看
          </b-button>
          <b-popover
            :target="`${props.row.id}`"
            placement="top"
            triggers="hover"
          >
            <template #title> 查看物料二维码 </template>
            <span>
              <b-img thumbnail fluid :src="props.row.url" />
            </span>
          </b-popover>
        </span>

        <span v-else-if="props.column.field === 'createTime'">
          {{ timestampToTime(props.row.createTime) }}
        </span>
        <!-- Column: Action -->

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total }} 条 </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BForm,
  BModal,
  VBModal,
  BImg,
  BPopover,
  VBPopover,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BImg,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    vSelect,
    BForm,
    BModal,
    VBModal,
    BPopover,
    VBPopover,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-popover": VBPopover,
    Ripple,
  },
  data() {
    return {
      log: [],
      show: false,
      disabled: false,
      pageLength: 10,
      total: 0,
      cid: 0,
      did: 0,
      chanlid: "",
      desc: "",
      name: "",
      ChannelOptions: [],
      selectid: [],
      search: "",
      ChannelSelect: null,
      roleOptions: [],
      accountid: "",
      dir: false,
      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "渠道名称",
          field: "name",
        },
        {
          label: "部门名称",
          field: "dname",
        },
        {
          label: "随机码",
          field: "rid",
        },

        {
          label: "二维码",
          field: "url",
        },
        {
          label: "创建时间",
          field: "date",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "light-success",
        1: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt
      .queryActivityQrcode({
        cid: this.cid,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        // pagelength 一页显示多少条
        this.total = res.data.data.records;
        this.rows = res.data.data.rows;
      });
  },
  methods: {
    timestampToTime(cjsj) {
      const date = new Date(cjsj); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;
      const D = `${date.getDate()} `;
      const h = `${date.getHours()}:`;
      const m = `${date.getMinutes()}:`;
      const s = date.getSeconds();
      return Y + M + D + h + m + s;
    },

    selectionChanged(params) {
      console.log("params", JSON.stringify(params.selectedRows));
      this.selectid = params.selectedRows;
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.selectedRows.length}`,
          icon: "UserIcon",
          variant: "success",
        },
      });
    },
    golink(id) {
      this.$router.push({ name: "WechatEdit", params: { id } });
    },
    gochatroom(wechatid) {
      this.$router.push({ name: "ChatRoomManger", params: { wechatid } });
    },

    changeAcc(newVal) {
      this.accountOptions = [];
      this.did = newVal;
    },
    confirmText(id) {
      this.$swal({
        title: "确定要删除吗?",
        text: "删除后无法恢复!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          useJwt.deleteDevices({ ids: id }).then((res1) => {
            if (res1.data.code === 0) {
              this.$swal({
                icon: "success",
                title: "删除!",
                text: "删除成功",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              useJwt
                .queryDevices({
                  cid: this.cid,
                  page: 1,
                  rows: this.pageLength,
                })
                .then((res2) => {
                  this.total = res2.data.data.records;
                  this.rows = res2.data.data.rows;
                });
            } else {
              this.$swal({
                icon: "error",
                title: "删除失败!",
                text: "请联系管理员处理",
                customClass: {
                  confirmButton: "btn btn-error",
                },
              });
            }
          });
        }
      });
    },
    generate() {
      console.log("this.ChannelSelect.text", this.ChannelSelect);
      useJwt
        .ActivityQrcodeAdd({
          cid: this.cid,
          did: this.did,
          name: this.ChannelSelect.text,
          chanlid: this.ChannelSelect.value,
        })
        .then((res) => {
          console.log("提交结果", res);
          if (res.data.code == 0) {
            this.$swal({
              icon: "success",
              title: "生成成功!",
              text: "",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            useJwt
              .queryActivityQrcode({
                cid: this.cid,
                page: 1,
                rows: this.pageLength,
                desc: this.desc,
              })
              .then((res) => {
                // pagelength 一页显示多少条
                this.total = res.data.data.records;
                this.rows = res.data.data.rows;
              });
          }
        });
    },
    handleSearch(searching) {
      this.search = searching;
    },
    handleChangePage(page) {
      useJwt
        .queryDevices({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          wechatnick: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    handlePageChange(active) {
      useJwt
        .queryDevices({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows = res.data.data.rows;
          this.total = res.data.data.records;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }

      useJwt
        .queryDevices({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
  },
  mounted() {
    useJwt.queryByCid({ cid: this.cid }).then((response) => {
      if (response.data.code == 0) {
        const forArr = response.data.data;

        forArr.forEach((item, i) => {
          this.roleOptions.push({ text: item.name, value: item.id });
        });
      }
    });
    useJwt.queryChannelByCid({ cid: this.cid, type: 2 }).then((response) => {
      if (response.data.code == 0) {
        console.log("response", JSON.stringify(response));
        const forArr = response.data.data;
        forArr.forEach((item, i) => {
          this.ChannelOptions.push({ text: item.channelname, value: item.id });
        });
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
